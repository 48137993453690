import styled from '@emotion/styled';
import React from 'react';
import { LoveYou } from './LuvContent';

const WelcomeWarning = () => {
    const [open, setOpen] = React.useState(true);

    if (open) {
        return (
            // <Modal showCloseIcon={false} open={true} onClose={() => alert('hey')}>
            <Alert open={open}>
                <StyledLoveYou>WAARSCHUWING! Het bezoeken van deze website kan barfings veroorzaken wegens teveel geslijm.</StyledLoveYou>
                <Continue onClick={() => setOpen(false)}>Negeer</Continue>
            </Alert>
            // </Modal>
        );
    }
    return null;
};

export default WelcomeWarning;

const StyledLoveYou = styled(LoveYou)`
    padding: unset;
`;
const Continue = styled.button`
    border-style: none;
    width: 10rem;
    padding: 2rem;
    transition: all 0.25s;
    :hover {
        transform: scale(1.2);
    }
`;

const Alert = styled.div<{ open: boolean }>`
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: red;
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: all 0.25s;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`;
