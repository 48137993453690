import styled from '@emotion/styled';
import { compare } from 'bcryptjs';
import * as React from 'react';
import { HeaderDivider } from '../components/atoms/HeaderDivider';
import HearthShape from '../components/HearthShape';
import Page from '../components/Page';
import WelcomeWarning from '../components/WelcomeWarning';
import IndexLayout from '../layouts';
import { breakpoints, colors } from '../styles/variables';

const checkIfPasswordIsValid = async (pass: string) => {
    return await compare(pass, '$2a$12$OHu7/lWwrNf2NcvnzFOdpe477qJX1tzYlcA4kmDE1hDzVPqpYzakC');
};
const IndexPage: React.FunctionComponent = () => {
    const [passValue, setPassValue] = React.useState('');
    const windowGlobal = typeof window !== 'undefined' && window;
    if (windowGlobal && windowGlobal.localStorage) {
        const password = localStorage.getItem('password');
        if ((password || passValue) && checkIfPasswordIsValid(password || passValue)) {
            return (
                <IndexLayout>
                    <Page color={colors.ui.bright}>
                        <Wrapper>
                            <WelcomeWarning />
                            <Block>
                                <h1>Michelle,</h1>
                                <HeaderDivider />
                                <h5>{`Welkom op jouw website toetselien!. Ik heb dit gemaakt omdat we alweer 3 jaar samen zijn. Uiteraard piecie slijm maaar zo af en toe kan dat wel toch?! Allereerst wil ik even zeggen dat vind dat wij het super goed doen saampjes. Menig koppel vecht elkaar de deur uit bij <40 m2, maar wij fixen het gewoon!`}</h5>
                            </Block>
                            <HearthShape withGuide direction={'down'} />

                            {/* <Project>
                            <Thumbnail>
                                <ThumbnailProjectTitle>Some Project</ThumbnailProjectTitle>
                            </Thumbnail>
                            <Info>
                                <h2>Some project</h2>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur ipsum dolor architecto vero saepe nihil
                                    ipsam, fugit, placeat molestiae facere odit incidunt eveniet at commodi nisi quaerat a minus ex.
                                </p>
                            </Info>
                        </Project> */}
                            {/* <p>
                            My name is <strong>Chris Neven</strong>
                        </p>
                        <p>
                            I'm a <strong style={{ textDecoration: 'line-through' }}>Software Engineer</strong> computernerd.
                        </p>
                        <Link to="/page-2/">Go to page 2</Link> */}
                        </Wrapper>
                    </Page>
                </IndexLayout>
            );
        }
    }

    // }
    // navigate('/login');
    // return null;

    return (
        <IndexLayout>
            <Form
                onSubmit={async e => {
                    e.preventDefault();
                    const password: string = e.currentTarget.password.value;
                    console.log(password);
                    const valid = await checkIfPasswordIsValid(password);
                    if (valid) {
                        localStorage.setItem('password', password);
                        setPassValue(password);
                    } else {
                        alert('Wachtwoord is fout!!!!');
                    }
                }}
            >
                <div style={{ width: '20rem' }}>
                    <h1>Hallo meisjes</h1>
                    <HeaderDivider />
                </div>

                <input type="password" name="password" placeholder="wachtwoord" />
                <Submit type="submit">ENTER</Submit>
            </Form>
        </IndexLayout>
    );
};

export default IndexPage;
const Submit = styled.button`
    margin-top: 2rem;
    border-style: none;
    width: 10rem;
    padding: 1rem;
    color: white;
    border-radius: 2.5rem;
    background: ${colors.ui.bright};
    cursor: pointer;
    transition: all 0.25s;
    :hover {
        transform: scale(1.2);
    }
`;
const Form = styled.form`
    font-family: Raleway;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${colors.ui.whisper};
    > input {
        background: unset;
        border: unset;
        font-size: 2rem;
        padding: 1rem;
        width: 16rem;
        color: grey;
        box-shadow: 0 1px 20px 5px rgba(0, 0, 0, 0.3);
        @media (min-width: ${breakpoints.md}px) {
            width: 20rem;
        }
        transition: all 0.25s;
        :focus {
            transform: scale(1.2);
            box-shadow: 0 1px 80px 5px rgba(0, 0, 0, 0.3);
        }
    }
`;
const Block = styled.div`
    /* height: 30rem; */
    width: 100%;
    @media (min-width: ${breakpoints.md}px) {
        width: 50vw;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    @media (min-width: ${breakpoints.md}px) {
        justify-content: flex-end;
    }
`;
